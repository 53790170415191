import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';

import offers from '@/offers';

export const main = defineStore('main', () => {
  let offerTitle = ref('Buy 1 Get 1 Free');
  let email = ref(useLocalStorage('email', ''));
  let token = ref(useLocalStorage('token', ''));
  let user = ref(useLocalStorage('user', {}));
  let cartMap = ref(useLocalStorage('cartMap', {}));
  let cart = ref(useLocalStorage('cart', []));
  let customerLocation = ref(useLocalStorage('customerLocation', {}));
  let cartEmail = ref(useLocalStorage('cartEmail', ''));
  let couponCode = ref(useLocalStorage('couponCode', ''));
  let userInfo = ref(useLocalStorage('userInfo', {}));
  let affId = ref(useLocalStorage('affId', ''));
  let orderId = ref(useLocalStorage('orderId', ''));
  let razorOrderId = ref(useLocalStorage('razorOderId', ''));
  let order = ref(useLocalStorage('order', {}));
  let products = ref(useLocalStorage('products', []));
  let toastMsg = ref('');
  let toastMsgCart = ref('');
  let isEmailSubmitted = ref(useLocalStorage('emailSubmitted', false));

  let isEmailPopupRejected = ref(useLocalStorage('emailPopup', false));
  let isMemberPopupRejected = ref(useLocalStorage('memberPopup', false));

  let isLoadingSummary = ref(useLocalStorage('loadinSummary', false));
  let isLoginModalOpen = ref(useLocalStorage('loginModalOpen', false));
  let isCartModalOpen = ref(useLocalStorage('cartModalOpen', false));
  let isBankDetailsModalOpen = ref(useLocalStorage('bankDetailsModal', false));
  let isShowAffiliateLink = ref(useLocalStorage('affiliateLink', false));
  let isUpsellModalOpen = ref(useLocalStorage('upsellModal', false));
  let isMenuActive = ref(useLocalStorage('menu', false));
  let isDarkMode = ref(useLocalStorage('darkMode', false));
  let currProd = ref(useLocalStorage('currProd', {}));

  const userTypeToRegister = ref(useLocalStorage('userTypeToRegister', 'customer'));

  function setCurrProd(prod, from) {
    currProd.value = prod;
    currProd.value['from'] = from;
    console.log('currProd in pinia', currProd.value);
  }

  function toast(msg) {
    toastMsg.value = msg;
    setTimeout(() => {
      toastMsg.value = '';
    }, 2000);
  }

  function toastCart(msgCart) {
    toastMsgCart.value = msgCart;
    setTimeout(() => {
      toastMsgCart.value = '';
    }, 2000);
  }

  function clearVuex() {
    isEmailSubmitted.value = true;
    email.value = '';
    isLoadingSummary.value = false;
    isLoginModalOpen.value = false;
    isCartModalOpen.value = false;
    isBankDetailsModalOpen.value = false;
    isShowAffiliateLink.value = false;
    isUpsellModalOpen.value = false;
    isMenuActive.value = false;
    token.value = '';
    user.value = {};
    cartMap.value = {};
    cart.value = [];
    customerLocation.value = {};
    cartEmail.value = '';
    couponCode.value = '';
    userInfo.value.value = {};
    affId.value = '';
    orderId.value = '';
    razorOrderId.value = '';
    order.value.value = {};
    isEmailPopupRejected.value = false;
    isMemberPopupRejected.value = false;
  }

  function clearCart() {
    cartMap.value = {};
    cart.value = [];
    order.value = {};
  }

  function setOrder(value) {
    order.value = value;
  }

  function applyBogof(diff) {
    cart.value.forEach((product) => {
      //////////////
      if (offers.isBuyOneGetOne(product)) {
        if (product.qty % 2 !== 0 && product.qty > 2) {
          product.qty += diff;
        }

        if (product.qty < 2) {
          product.qty = 2;
        }

        // to prevent further more discount
        if (!product.strikedPrice) {
          const price = parseFloat(product.price);
          product.strikedPrice = price;
          product.price = parseFloat((50 * price) / 100);
          let totalPrice = price * product.qty;
          product.discount = parseFloat((50 * totalPrice) / 100).toFixed(2);
        }
      }
    });
  }

  function checkMinOrderValueForUserTypes(userType, grandTotal) {
    let msg = '';
    if (userType === 'distributor' && grandTotal < 50000) {
      msg = 'Minimum Order price (Cart Value) should be Rs. 50000';
    } else if (userType === 'retailer' && grandTotal < 25000) {
      msg = 'Minimum Order price (Cart Value) should be Rs. 25000';
    } else if (userType === 'reseller' && !main().user.isApproved && grandTotal < 4400) {
      msg = 'Minimum Order price (Cart Value) at Reseller Price, for first should be Rs. 4400';
    } else if (userType === 'reseller' && main().user.isApproved && grandTotal < 1000) {
      msg = 'Minimum Order price (Cart Value) at Reseller Price should be Rs. 1000';
    } else if (userType === 'member' && grandTotal < 1000) {
      // Either approved or not approved, member should have min cart value 1000
      // msg = 'Members have to buy products worth Rs. 1000 (Cart Value) per order. Please add more products. If you wish to buy fewer products, please login using a different non-member email id.';
      msg = 'Minimum Order price (Cart Value) Member Price should be Rs. 1000';
    } else if (userType === 'affiliate' && grandTotal < 500) {
      msg = 'Minimum Order Price (Cart Value) should be Rs. 500';
    }
    return msg;
  }

  return {
    currProd,
    offerTitle,
    toastMsg,
    toastMsgCart,
    email,
    token,
    user,
    cartMap,
    cart,
    customerLocation,
    cartEmail,
    couponCode,
    userInfo,
    affId,
    orderId,
    razorOrderId,
    order,
    products,
    isEmailSubmitted,

    isEmailPopupRejected,
    isMemberPopupRejected,

    isLoadingSummary,
    isLoginModalOpen,
    isCartModalOpen,
    isBankDetailsModalOpen,
    isShowAffiliateLink,
    isUpsellModalOpen,
    isMenuActive,
    isDarkMode,
    userTypeToRegister,

    setCurrProd,
    toast,
    toastCart,
    clearVuex,
    clearCart,
    setOrder,
    applyBogof,
    checkMinOrderValueForUserTypes,
  };
});
